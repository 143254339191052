<template>
  <div class="flex flex-col h-screen">
    <NavbarComponent />
    <main class="mt-20">
      <div class="grid grid-cols-1 md:grid-cols-5 gap-2 p-3">
        <div class="col-span-2 flex">
          <img src="./../../assets/golfigo/golf.png" alt="" />
        </div>
        <div class="col-span-3 flex justify-center">
          <div class="flex flex-col md:w-4/5 mx-auto mt-20">
            <h1 class="text-gray-900 text-4xl">
              {{ $t("NavbarFirst.login") }}
            </h1>
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2 flex flex-col justify-between"
                @submit.prevent="validationForm"
              >
                <div class="mt-7">
                  <!-- email -->
                  <b-form-group
                    :label="$t('LoginPage.EmailLabel')"
                    label-for="login-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('LoginPage.EmailLabel')"
                      vid="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="login-email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false : null"
                        class="p-2"
                        name="login-email"
                        placeholder="sven@exempel.se"
                      />
                      <small class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div class="mt-7">
                  <!-- forgot password -->
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">
                        {{ $t("LoginPage.PasswordLabel") }}
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="{
                          'is-invalid': errors.length > 0,
                        }"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge p-2"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <div class="flex justify-end">
                    <b-link :to="{ name: 'forgot-password' }">
                      <small class="text-theme-sidebar text-lg">
                        {{ $t("LoginPage.ForgotPassword") }}
                      </small>
                    </b-link>
                  </div>
                </div>

                <!-- checkbox -->
                <!-- <b-form-group>
                    <b-form-checkbox
                        id="remember-me"
                        v-model="status"
                        name="checkbox-1"
                    >
                        {{ $t('LoginPage.RememberMe') }}
                    </b-form-checkbox>
                </b-form-group> -->

                <div class="mt-10 md:mt-20 w-full md:w-1/3 md:mx-auto">
                  <!-- submit buttons -->
                  <button
                    type="submit"
                    :disabled="processing"
                    class="flex w-full px-4 py-1 focus:outline-none bg-theme-sidebar text-white rounded-md"
                  >
                    <span
                      v-if="processing"
                      class="flex justify-center items-center space-x-2 whitespace-nowrap"
                    >
                      <svg
                        class="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <span
                        class="text-xl md:text-xl text-white whitespace-nowrap"
                        >{{ $t("Message.wait") }}</span
                      >
                    </span>
                    <span
                      v-else
                      class="text-xl md:text-xl whitespace-nowrap text-center w-full text-white"
                    >
                      {{ $t("LoginPage.Button") }}
                    </span>
                  </button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </main>
    <FooterComponent />
  </div>
</template>

<script>
/* eslint-disable global-require */
import NavbarComponent from "./components/Navbar.vue";
import FooterComponent from "./components/Footer.vue";
import FaqRenderVue from "./custom/AccordionFaq.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "LoginPage",
  components: {
    ValidationProvider,
    ValidationObserver,
    NavbarComponent,
    FooterComponent,
    FaqRenderVue,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      processing: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  mounted() {
    this.locale = localStorage.getItem("lang");
    if (this.$route.query.q) {
      this.popup(
        this.$t("Error.TokenExpired.Message"),
        "danger",
        this.$t("Error.TokenExpired.Title"),
        "AlertTriangleIcon"
      );
    }
  },
  methods: {
    async validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        this.processing = true;
        if (success) {
          this.$useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((res) => {
              const { data } = res;
              this.processing = false;
              if (res.status === 401) {
                this.popup(
                  res.message,
                  "danger",
                  this.$t("Message.Failed"),
                  "AlertTriangleIcon"
                );
                this.$refs.loginValidation.setErrors({
                  email: [res.message],
                });
              } else {
                this.$useJwt.setToken(data.data.token);
                this.$useJwt.setRefreshToken(data.data.token);
                localStorage.setItem("userData", JSON.stringify(data.data));

                // If conditions are met, set the flag in local storage
                if (data.data.is_git_api_user && !data.data.golfclub_is_activated_in_git) {
                    localStorage.setItem("showIntegrationPopup", "true");
                }

                this.$useJwt
                  .customUser({ URL: "/me", method: "get" })
                  .then((resp) => {
                    if (
                      resp?.data?.data?.connect_info &&
                      resp?.data?.data?.connect_info.connect_endpoint
                    ) {
                      localStorage.setItem(
                        "__connectInfo__",
                        JSON.stringify(resp?.data?.data?.connect_info)
                      );
                    } else {
                      localStorage.removeItem("__connectInfo__");
                    }

                    this.$useJwt
                      .hiddenResource(
                        {
                          method: "get",
                          URL: `/${resp.data.data.hidden_resources_id}`,
                        },
                        {}
                      )
                      .then((resp) => {
                        localStorage.setItem(
                          "hiddenResource",
                          JSON.stringify(resp.data)
                        );
                        this.$router.push({ name: "dashboard" }).then(() => {
                          this.popup(
                            this.$t("LoginPage.WelcomeMessage"),
                            "success",
                            `${this.$t("Message.welcome")} ${
                              data.data.full_name
                            }`,
                            "CoffeeIcon"
                          );
                        });
                      });
                  });
              }
            })
            .catch((error) => {
              this.processing = false;
              if (error.response) {
                let msg =
                  localStorage.getItem("lang") === "sv"
                    ? error.response.data.message
                    : error.response.data.eng_message;

                this.$refs.loginValidation.setErrors({
                  email: [msg],
                });
                this.popup(
                  msg,
                  "danger",
                  this.$t("Message.Failed"),
                  "AlertTriangleIcon"
                );
              }
            });
        }
      });
    },
    popup(message, variant, title, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
          text: message,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// .support-gradient {
//     font-style: normal;
//     font-weight: 794;
//     font-size: 40px;
//     line-height: 48px;
//     text-align: center;
//     background: linear-gradient(266.01deg, #0B0875 12.33%, #00D090 113.67%);
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//     background-clip: text;
//     text-fill-color: transparent;
// }
</style>
